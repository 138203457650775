import {DEFAULT_ACTIVE_CHAIN_ID} from "config/constants";
import {getBridgeTwoWayInfo, getTokenLOGO} from "utils/bridge";
import { chains, tokens } from 'config/constants/assets';
import { getBridgeTokensByChain } from "utils/backend";

export const fetchBridgeInfo2 = async (chainId: string): Promise<any> => {
    const bridgeInfo = getBridgeTwoWayInfo(Number(chainId));
    try {
        if( bridgeInfo.length > 0 ){
            const chainInfo = bridgeInfo[0];
            if (Object.isFrozen(chainInfo)) {
                // console.log("The object is frozen.");
            }

            const chainsInfo: any = {}
            chainsInfo.chainId = chainInfo.chainId
            chainsInfo.chainLogo = chainInfo.chainLogo
            chainsInfo.chainsTo = []
            // Fetch tokensFROM FROM DB
            for ( let x = 0; x < chainInfo.chainsTo.length; x+= 1 ){
                if( chainInfo.chainsTo[x].enabled === 1 )
                {
                    const tokensF = []
                    const tokensDB = await getBridgeTokensByChain({chain: Number(chainId)})
    
                    for( let i = 0; i < tokensDB.length; i+=1 ){
                        if( tokensDB[i].enabled && tokensDB[i].chain_src === Number(chainId) && chainInfo.chainsTo[x].chainId === tokensDB[i].chain_dst ){
                            const token2 = {
                                name: tokensDB[i].token_from_name,
                                address: tokensDB[i].token_from,
                                symbol: tokensDB[i].token_from_name,
                                tokenLogo: getTokenLOGO(tokensDB[i].token_from_name) ? getTokenLOGO(tokensDB[i].token_from_name) :  tokens.noimagetoken,
                                tokenTo: {
                                    name: tokensDB[i].token_to_name,
                                    address: tokensDB[i].token_to,
                                    symbol: tokensDB[i].token_to_name,
                                    decorativeName: tokensDB[i].token_to_name
                                }
                            }
                            tokensF.push(token2)
                        }
                    }
    
                    if( tokensF.length > 0  ){
                        const chain = {chainName: chainInfo.chainsTo[x].chainName,
                            chainId: chainInfo.chainsTo[x].chainId,
                            chainLogo: chainInfo.chainsTo[x].chainLogo,
                            contractAddress: chainInfo.chainsTo[x].contractAddress,
                            // tokensFrom: tokensF
                        tokensFrom: tokensF
                        }
                        chainsInfo.chainsTo.push(chain)
                    }
                    else {
    
                        const chain = {chainName: chainInfo.chainsTo[x].chainName,
                            chainId: chainInfo.chainsTo[x].chainId,
                            chainLogo: chainInfo.chainsTo[x].chainLogo,
                            contractAddress: chainInfo.chainsTo[x].contractAddress,
                            // tokensFrom: tokensF
                            tokensFrom: chainInfo.chainsTo[x].tokensFrom
        
                        }
                        chainsInfo.chainsTo.push(chain)
                    }
                }
            }
            return {data: chainsInfo};
        }
    } catch (error: any) {
        console.log(error)
        return {
            data: [],
        };
    }
    return {
        data: [],
    };
};
