import { bridgeInfos, bridgeNftInfos } from 'config/constants/bridge';
import BridgeFTMAbi from 'config/abi/BridgeFTM.json';
import BridgeNFTAbi from 'config/abi/BridgeNFT.json';
import Bridge2Abi from 'config/abi/Bridge2.json';
import {BRIDGE_LOGOS, bridgeTwoWayInfos} from '../config/constants/bridgeTwoWay';

export const getBridgeTwoWayInfo = (chainId: number) => {
    const bridge = bridgeTwoWayInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
    return bridge;
};

export const getBridgeInfo = (chainId: number) => {
    const bridge = bridgeInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
    return bridge;
};

export const getBridgeNftInfo = (chainId: number) => {
    const bridge = bridgeNftInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
    return bridge;
};

export const getBridgeABI = (chainId: number) => {
    if( chainId === 250 ){
        return BridgeFTMAbi
    }
    if( chainId === 137 ){
        return BridgeFTMAbi
    }
    return [];
};

export const getBridgeNFTABI = (chainId: number) => {
    if( chainId === 250 ){
        return BridgeNFTAbi
    }
    if( chainId === 137 ){
        return BridgeNFTAbi
    }
    return [];
};

export const getBridge2ABI = (chainId: number) => {
    return Bridge2Abi;
};

export const getTokenLOGO = (address?: string) => {
  if (!address) return '';
  console.log(address)
  const logo = BRIDGE_LOGOS.find((row: any) => row.symbol === address);
  if (!logo) return '';
  return logo.logo;
};
