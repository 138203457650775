import { icons } from 'config/constants/assets';

export interface RouteType {
  key: string;
  text: string;
  url: string;
}

export const routes: { [key: number]: RouteType[] } = {
  250: [
    { key: 'overview', text: 'Overview', url: '/vaults' },
    { key: 'earn', text: 'Earn', url: '/earn' },
    { key: 'dex', text: 'Based DEX', url: '/dex'},
    { key: 'bridge', text: 'Bridge', url: '/bridge' },
     { key: 'nft', text: 'NFTs', url: '/nft' },
  ],
  137: [
    { key: 'overview', text: 'Overview', url: '/vaults' },
    { key: 'earn', text: 'Earn', url: '/earn' },
    // { key: 'dex', text: 'Based DEX', url: '/dex' },
    { key: 'bridge', text: 'Bridge', url: '/bridge' },
  ],

  43114: [
    { key: 'overview', text: 'Overview', url: '/vaults' },
    { key: 'earn', text: 'Earn', url: '/earn' },
    // { key: 'earn', text: 'Earn', url: '/earn' },
    // { key: 'dex', text: 'Based DEX', url: '/dex' },
    // { key: 'bridge', text: 'Bridge', url: '/bridge' },
  ],
  8453: [
    { key: 'overview', text: 'Overview', url: '/vaults' },
    // { key: 'earn', text: 'Earn', url: '/earn' },
    // { key: 'dex', text: 'Based DEX', url: '/dex' },
    // { key: 'bridge', text: 'Bridge', url: '/bridge' },
  ],
  324: [
    { key: 'overview', text: 'Overview', url: '/vaults' },
    // { key: 'earn', text: 'Earn', url: '/earn' },
    // { key: 'dex', text: 'Based DEX', url: '/dex' },
    // { key: 'bridge', text: 'Bridge', url: '/bridge' },
  ],
  146: [
    { key: 'bridge', text: 'Bridge', url: '/bridge' }
  ]
};

export const extraMenuItems: { [key: number]: any[] } = {
  250: [
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'Overview', key: 'overview', link: '/overview', color: '#CCC292' },
        { name: 'Earn', key: 'earn', link: '/earn', color: '#CCC292' },
        { name: 'Base Dex', key: 'dex', link: '/dex', color: '#CCC292' },
        { name: 'Bridge', key: 'bridge', link: '/bridge', color: '#CCC292' },
        { name: 'NFTs',
        key: 'NFTs',
        color: '#CCC292',// '#A5CCB5',
        link: '/nft'
      },
      ],
    },
    {
      name: 'Based extras',
      icon: icons.menuAnalytics,
      key: 'based extras',
      hasSubItems: true,
      subItems: [
        { name: 'Exploder', key: 'exploder', color: '#CCC292' },
        { name: 'VDC Leaderboard', key: 'vdc leaderboard', color: '#CCC292' },
        { name: 'Theatre', key: 'theatre', link: '/theatre', color: '#807CCC' },
        {
          name: 'OTC engine',
          key: 'otc engine',
          link: 'https://next-gen.basedfinance.io/exchange',
          color: '#CC8B87',
          isExternal: true,
        },
        {
          name: 'Twisted nodes',
          key: 'twisted nodes',
          link: 'https://next-gen.basedfinance.io/twistednodes',
          color: '#A5CCB5',
          isExternal: true,
        }
      ],
    },
    {
      name: 'Charts',
      icon: icons.menuFourRect,
      key: 'charts',
      // link: 'https://dexscreener.com/fantom/0x432a4654bc67ed86b3119cd256c490f2cea1e42a',
      isExternal: true,
      hasSubItems: false,
    },
    {
      name: 'Analytics',
      icon: icons.menuAnalytics,
      key: 'analytics',
      link: 'https://info.basedfinance.io/',
      isExternal: true,
      hasSubItems: false,
    },
  ],
  137: [
    {
      name: 'Main menu',
      icon: icons.menuAnalytics,
      key: 'based extras',
      hasSubItems: true,
      subItems: [
        { name: 'Overview', key: 'overview', link: '/overview', color: '#CCC292' },
        { name: 'Earn', key: 'earn', link: '/earn', color: '#CCC292' },
        { name: 'Bridge', key: 'bridge', link: '/bridge', color: '#CCC292' },
        { name: 'NFTs', key: 'nft customizations', color: '#A5CCB5' },
      ],
    },
  ],
  43114: [
    {
      name: 'Main menu',
      icon: icons.menuAnalytics,
      key: 'based extras',
      hasSubItems: true,
      subItems: [
        { name: 'Overview', key: 'overview', link: '/overview', color: '#CCC292' },
        { name: 'Earn', key: 'earn', link: '/earn', color: '#CCC292' },
        { name: 'NFTs', key: 'nft customizations', color: '#A5CCB5' },
      ],
    },
  ],
  8453: [
    {
      name: 'Main menu',
      icon: icons.menuAnalytics,
      key: 'based extras',
      hasSubItems: true,
      subItems: [
        { name: 'Overview', key: 'overview', link: '/overview', color: '#CCC292' },
        { name: 'NFT customizations', key: 'nft customizations', color: '#A5CCB5' },
      ],
    },
  ],
  324: [
    {
      name: 'Main menu',
      icon: icons.menuAnalytics,
      key: 'based extras',
      hasSubItems: true,
      subItems: [
        { name: 'Overview', key: 'overview', link: '/overview', color: '#CCC292' },
        { name: 'NFT customizations', key: 'nft customizations', color: '#A5CCB5' },
      ],
    },
  ],
};
