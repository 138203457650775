import { chains, tokens } from 'config/constants/assets';

const bridgeTwoWayInfo = [
  {
    chainId: 250,
    chainLogo: chains.fantom,
    chainsTo:[
      {
        enabled: 0,
        chainName: 'AVAX',
        chainId: 30106,
        chainLogo: chains.avalanche,
        contractAddress: "0x7743fCB71255C708A56e1b6417a6F71882f9bbEE",
        tokensFrom: [
          {
            name: "TEST",
            address: "0x14007EC3b333D2c9710F31730c1EF64A86AE3a27",
            symbol: "TEST",
            tokenLogo: tokens.noimagetoken,
            tokenTo: {
              name: "ATEST",
              address: "0x82Bf8cf670428B5401569d28d3555FE899BB6bD2",
              symbol: "ATEST",
              decorativeName: "ATEST"
            },
          },
          {
            name: "TEST2",
            address: "0xf4Ac65Ac9dB0F16DCcB374D060C9675cF9Cb39Ff",
            symbol: "TEST2",
            tokenLogo: tokens.noimagetoken,
            tokenTo: {
              name: "ATEST2",
              address: "0xd97142d85386C614605d0Cc0290DC2e7193DC087",
              symbol: "ATEST2",
              decorativeName: "ATEST2"
            },
          }
      ]
      },
      {
        enabled: 1,
        chainName: 'SONIC',
        chainId: 30332,
        chainLogo: chains.sonic,
        contractAddress: "0xBF63a0EEF4565511aF859671b4096D07Ea910310",
        tokensFrom: [
          // {
          //   name: "BEARZ WIF GUNZ",
          //   address: "0x8d3c36f0c4f3bcae6fa2496e5be882e3677143ee",
          //   symbol: "BEARZ WIF GUNZ",
          //   tokenLogo: tokens.bearz_bridge,
          //   tokenTo: {
          //     name: "",
          //     address: "",
          //     symbol: "",
          //     decorativeName: ""
          //   },
          // },
          // {
          //   name: "SKITTY",
          //   address: "0xad531012c0651a85A212F10fC97b3EC15684548a",
          //   symbol: "SKITTY",
          //   tokenLogo: tokens.skitty_bridge,
          //   tokenTo: {
          //     name: "",
          //     address: "",
          //     symbol: "",
          //     decorativeName: ""
          //   },
          // },
          // {
          //   name: "SONICX",
          //   address: "0xc83A698508d671f08C6775AeC4c8DA46bD828613",
          //   symbol: "SONICX",
          //   tokenLogo: tokens.sonicx_bridge,
          //   tokenTo: {
          //     name: "",
          //     address: "",
          //     symbol: "",
          //     decorativeName: ""
          //   },
          // }
        ]
      }
    ]
  },
  {
    chainId: 146,
    chainLogo: chains.sonic,
    chainsTo:[
      {
        enabled: 1,
        chainName: 'FTM',
        chainId: 30112,
        chainLogo: chains.fantom,
        contractAddress: "0x713A8C8CaD2982397572E1e1E8fD8799F7eA3b37",
        tokensFrom: [{}]
      }
    ]
  },
  {
    chainId: 43114,
    chainLogo: chains.avalanche,
    chainsTo:[
      {
        enabled: 0,
        chainName: 'FTM',
        chainId: 30112,
        chainLogo: chains.fantom,
        contractAddress: "0xFDF2923CA003b1465936A46FB217d8b7C73B5050",
        tokensFrom: [{
          name: "ATEST",
          address: "0x82Bf8cf670428B5401569d28d3555FE899BB6bD2",
          symbol: "ATEST",
          tokenLogo: tokens.noimagetoken,
          tokenTo: {
            name: "TEST",
            address: "0x14007EC3b333D2c9710F31730c1EF64A86AE3a27",
            symbol: "TEST",
            decorativeName: "TEST"
          }
        },
        {
          name: "ATEST2",
          address: "0xd97142d85386C614605d0Cc0290DC2e7193DC087",
          symbol: "ATEST2",
          tokenLogo: tokens.noimagetoken,
          tokenTo: {
            name: "TEST2",
            address: "0xf4Ac65Ac9dB0F16DCcB374D060C9675cF9Cb39Ff",
            symbol: "TEST2",
            decorativeName: "TEST2"
          },
        }
      ]
      }
    ]
  }
]

export interface LogoInfo {
  symbol: string;
  logo: any;
}

export const BRIDGE_LOGOS: LogoInfo[] = [
  {  symbol: "SonicX", logo: tokens.sonicx_bridge },
  {  symbol: "SKITTY", logo: tokens.skitty_bridge },
  {  symbol: "BEARZ WIF GUNZ", logo: tokens.bearz_bridge },
  {  symbol: "PHANIC", logo: tokens.phantic_bridge},
  {  symbol: "CONK2.0", logo: tokens.conk_bridge },
  { symbol: "ECO", logo: tokens.eco_bridge},
  { symbol: "Wall Street Bets", logo: tokens.wallstreet_bridge}
];

const bridgeTwoWayInfos = [...bridgeTwoWayInfo];

export {bridgeTwoWayInfos}