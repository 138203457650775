// icons
import hamburgerIcon from 'assets/images/icons/hamburger-icon.svg';
import docsIcon from 'assets/images/icons/docs-icon.svg';
import discordIcon from 'assets/images/icons/discord-icon.svg';
import faceBookIcon from 'assets/images/icons/facebook-icon.svg';
import githubIcon from 'assets/images/icons/github-icon.svg';
import instagramIcon from 'assets/images/icons/instagram-icon.svg';
import telegramIcon from 'assets/images/icons/telegram-icon.svg';
import twitterIcon from 'assets/images/icons/twitter-icon.svg';

import circleDownIcon from 'assets/images/icons/circle-down-icon.svg';
import circleUpIcon from 'assets/images/icons/circle-up-icon.svg';
import dollarIcon from 'assets/images/icons/dollar-icon.svg';
import chevronDownIcon from 'assets/images/icons/chevron-down-icon.svg';

import bankIcon from 'assets/images/icons/bank-icon.svg';
import circulatingIcon from 'assets/images/icons/circulating-icon.svg';
import coinsIcon from 'assets/images/icons/coins-icon.svg';
import stampIcon from 'assets/images/icons/circle-stamp-icon.svg';
import templeIcon from 'assets/images/icons/temple-icon.svg';
import walletIcon from 'assets/images/icons/wallet-icon.svg';
import marketIcon from 'assets/images/icons/market-icon.png';
import timeIcon from 'assets/images/icons/time-icon.png';

// iamges
import overviewBannerImg from 'assets/images/overview-banner.svg';
import tokenBurnCardBasedImg from 'assets/images/burn_card1.png';
import tokenBurnCardBshareImg from 'assets/images/burn_card2.png';
import tokenBoilerRoomCardImg from 'assets/images/boiler_room_card.png';
import overviewBannerHeroImg from 'assets/images/greece_hero.svg';
import bottomBannerImg from 'assets/images/footer_bannder.png';
import overviewTvlImg from 'assets/images/overview-tvl.png';
import defautAccountPhotoImg from 'assets/images/default-user.svg';
import tokenWrapperImg from 'assets/images/token-wrapper.svg';
import defaultNewsImg from 'assets/images/news-default.png';
import swapIcon from 'assets/images/swap.svg';

// tokens
import token1 from 'assets/images/tokens/token1.svg';
import token2 from 'assets/images/tokens/token2.svg';
import token3 from 'assets/images/tokens/token3.svg';
import token4 from 'assets/images/tokens/token4.svg';

import basedToken from 'assets/images/tokens/based.svg';
import bbondToken from 'assets/images/tokens/bbond.svg';
import bshareToken from 'assets/images/tokens/bshare.svg';
import obolToken from 'assets/images/tokens/obol.svg';
import smeltToken from 'assets/images/tokens/smelt.svg';
import rewardObol from 'assets/images/tokens/reward_obol.svg';
import rewardUsdc from 'assets/images/tokens/reward_usdc.svg';
import rewardSmelt from 'assets/images/tokens/reward_smelt.svg';
import rewardTestSmelt from 'assets/images/tokens/reward_test_smelt.webp';
import staterToken from 'assets/images/tokens/stater.png';
import noimagetoken from 'assets/images/tokens/noimagetoken.jpeg';

// lp tokens
import basedFtmLpToken from 'assets/images/tokens/lp/based-ftm-lp.svg';
import bshareFtmLpToken from 'assets/images/tokens/lp/bshare-ftm-lp.svg';
import obolFtmLpToken from 'assets/images/tokens/lp/obol-ftm-lp.svg';
import smeltFtmLpToken from 'assets/images/tokens/lp/smelt-ftm-lp.svg';
import bbondStakingToken from 'assets/images/tokens/bbond2.svg';
import basedMaticToken from 'assets/images/tokens/lp/based-matic.svg'

// account tokens
import accountObolToken from 'assets/images/tokens/account-tokens/obol.png';
import accountSmeltToken from 'assets/images/tokens/account-tokens/smelt.png';

import godFootprintToken from 'assets/images/tokens/account-tokens/god-footprint.png';
import obolFtmReceiptToken from 'assets/images/tokens/account-tokens/obol-ftm-receipt.png';
import smeltFtmReceiptToken from 'assets/images/tokens/account-tokens/smelt-ftm-receipt.png';
import godNftGif from 'assets/images/tokens/account-tokens/god-nft.gif';

// wallets
import metamask from 'assets/images/wallets/metamask.svg';
import walletConnect from 'assets/images/wallets/wallet-connect.svg';
import coinbase from 'assets/images/wallets/coinbase.svg';
import other from 'assets/images/wallets/other.svg';
import dexscreener from 'assets/images/wallets/dex-screener.png';

// chains
import ethereumLogo from 'assets/images/chains/ethereum.svg';
import fantomLogo from 'assets/images/chains/fantom.svg';
import fantomLogo1 from 'assets/images/chains/fantom1.svg';
import avalancheLogo from 'assets/images/chains/avalanche.svg';
import polygonLogo from 'assets/images/chains/polygon.svg';
import polygonLogo1 from 'assets/images/chains/polygon1.svg';
import zkChainLogo from 'assets/images/chains/zksync.svg'
import baseChainLogo from 'assets/images/chains/baseChain.jpg';
import polygonLogo2 from 'assets/images/chains/polygon.jpeg';
import sonicLogo from 'assets/images/chains/sonic.svg';
// header
import chevronDownIcon1 from 'assets/images/icons/chevron-down-icon1.svg';

// staking
import stakingLogo from 'assets/images/staking/staking.svg';
import dashboardLogo from 'assets/images/staking/dashboard.svg';
import stakingMenuLogo from 'assets/images/staking/stakingMenu.svg';
import farmsMenuLogo from 'assets/images/staking/farmsMenu.svg';
import nftMenuLogo from 'assets/images/staking/nftIcon.png';
// extra menus
import analyticsIcon from 'assets/images/icons/analytics-icon.svg';
import avatarDefaultIcon from 'assets/images/icons/avatar-default-icon.svg';
import basedLogoMenuIcon from 'assets/images/icons/based-logo-menu-icon.svg';
import chartIcon from 'assets/images/icons/chart-icon.svg';
import fourRectIcon from 'assets/images/icons/four-rect-icon.svg';
import portfolioAssetsIcon from 'assets/images/icons/portfolio-assets-icon.svg';

// nft
import nftUpgradeImg from 'assets/images/nfts/nft-upgrade.png';
import nftDowngradeImg from 'assets/images/nfts/nft-downgrade.png';
import nftConvertArrowImg from 'assets/images/nfts/nft-convert-arrow.png';
import wramiNft from 'assets/images/nfts/WramiMan.png';

// overview
import overviewTokens from 'assets/images/overview/tokens.svg';
import overviewLiquidity from 'assets/images/overview/liquidity.svg';
import overviewProtocols from 'assets/images/overview/protocols.svg';

// protocols
import flairdexProtocolImg from 'assets/images/partners/flair_protocol.png';
import glacierProtocolImg from 'assets/images/partners/glacier_protocol.svg';
import championProtocolImg from 'assets/images/partners/cham_protocol.svg';
import solisnekProtocolImg from 'assets/images/partners/solisnek_protocol.png';
import basoProtocolImg from 'assets/images/partners/baso_protocol.jpg';
import ezKaliburProtocolImg from 'assets/images/partners/kalibur.svg';
import veSyncProtocolImg from 'assets/images/partners/vesync.svg';
import drakulaProtocolImg from 'assets/images/partners/dracula_protocol.svg'

// bridge
import bearz_bridge from 'assets/images/tokens/bridge/brearztoken.jpeg';
import skitty_bridge from 'assets/images/tokens/bridge/skitty.png';
import sonicx_bridge from 'assets/images/tokens/bridge/sonicx.png';
import phantic_bridge from 'assets/images/tokens/bridge/phantic_bridge.jpg'
import conk_bridge from 'assets/images/tokens/bridge/conk_bridge.jpg'
import eco_bridge from 'assets/images/tokens/bridge/eco_bridge.webp'
import wallstreet_bridge from 'assets/images/tokens/bridge/wallstreet_bridge.png'

export const icons = {
  hamburger: hamburgerIcon,
  docs: docsIcon,
  discord: discordIcon,
  facebook: faceBookIcon,
  github: githubIcon,
  instagram: instagramIcon,
  telegram: telegramIcon,
  twitter: twitterIcon,

  circleDown: circleDownIcon,
  circleUp: circleUpIcon,
  dollar: dollarIcon,

  chevronDown: chevronDownIcon,
  chevronDown1: chevronDownIcon1,

  bank: bankIcon,
  circulating: circulatingIcon,
  coins: coinsIcon,
  stamp: stampIcon,
  dashboardMenu: dashboardLogo,
  stakingMenu: stakingMenuLogo,
  farmsMenu: farmsMenuLogo,
  nftMenu: nftMenuLogo,
  wallet: walletIcon,
  market: marketIcon,
  time: timeIcon,
  // extra menus
  menuAnalytics: analyticsIcon,
  menuAvatarDefault: avatarDefaultIcon,
  menuBasedLogoMenu: basedLogoMenuIcon,
  menuChart: chartIcon,
  menuFourRect: fourRectIcon,
  menuPortfolioAssets: portfolioAssetsIcon,

  // nft
  nftUpgrade: nftUpgradeImg,
  nftDowngrade: nftDowngradeImg,
  nftConvertArrow: nftConvertArrowImg,

  swapIcon
};

export const images = {
  overviewBanner: overviewBannerImg,
  overviewBannerHero: overviewBannerHeroImg,
  tokenBurnCardBased: tokenBurnCardBasedImg,
  tokenBurnCardBshare: tokenBurnCardBshareImg,
  tokenBoilerRoomCard: tokenBoilerRoomCardImg,
  bottomBanner: bottomBannerImg,
  overviewTvl: overviewTvlImg,
  defautAccountPhoto: defautAccountPhotoImg,
  tokenWrapper: tokenWrapperImg,
  defaultNews: defaultNewsImg,
};

export const protocols = {
  flairDexProtocol: flairdexProtocolImg,
  glacierProtocol: glacierProtocolImg,
  champProtocol: championProtocolImg,
  solisnekProtocol: solisnekProtocolImg,
  basoProtocol: basoProtocolImg,
  kaliburProtocol: ezKaliburProtocolImg,
  veSyncProtocol: veSyncProtocolImg,
  drakulaProtocol: drakulaProtocolImg
};

export const tokens = {
  based: basedToken,
  bbond: bbondToken,
  bshard: bshareToken,
  obol: obolToken,
  smelt: smeltToken,
  usdc: rewardUsdc,
  rewardBased: basedToken,
  rewardObol,
  rewardUsdc,
  rewardSmelt,
  rewardTestSmelt,
  rewardFtm: fantomLogo1,

  token1,
  token2,
  token3,
  token4,

  // lp
  obolFtmLp: obolFtmLpToken,
  smeltFtmLp: smeltFtmLpToken,
  basedFtmLp: basedFtmLpToken,
  bshareFtmLp: bshareFtmLpToken,
  bbondStaking: bbondStakingToken,
  staterSmelter: staterToken,
  basedMatic: basedMaticToken,
  noimagetoken,

  // bridge
  bearz_bridge,
  skitty_bridge,
  sonicx_bridge,
  phantic_bridge,
  conk_bridge,
  eco_bridge,
  wallstreet_bridge
};

export const accountTokens = {
  usdc: rewardUsdc,

  // single erc20 tokens
  obol: accountObolToken,
  smelt: accountSmeltToken,
  based: accountObolToken,
  bbond: accountSmeltToken,
  bshare: accountObolToken,

  // receipt token
  godFootprint: godFootprintToken,
  obolFtmReceipt: obolFtmReceiptToken,
  smeltFtmReceipt: smeltFtmReceiptToken,

  // nft
  godNft: godNftGif,
};

export const wallets = {
  metamask,
  walletConnect,
  coinbase,
  other,
  dexscreener,
};

export const chains = {
  ethereum: ethereumLogo,
  fantom: fantomLogo,
  fantom1: fantomLogo1,
  avalanche: avalancheLogo,
  polygon: polygonLogo,
  polygon1: polygonLogo1,
  zkSync: zkChainLogo,
  base: baseChainLogo,
  polygon2: polygonLogo2,
  sonic: sonicLogo,
};

export const staking = {
  logo: godNftGif,
  travelLogo: wramiNft
};

export const overviewPage = {
  tokens: overviewTokens,
  liquidity: overviewLiquidity,
  protocols: overviewProtocols
}